.rc-slider {
  padding: 0;
}

.rc-slider-rail,
.rc-slider-track {
  background-color: black;
  height: 1px;
}

.rc-slider-rail {
  background: lightgray;
}

.rc-slider-handle {
  width: 8px;
  height: 8px;
  border-color: black;
  border-width: 1px;
  margin-top: -4px;

  &:focus {
    border-color: black;
  }

  &:active {
    box-shadow: none;
  }
}
