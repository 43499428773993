$timeline-bar-1: rgba(70, 10, 193, 1);
$timeline-bar-2: rgba(200, 23, 135, 1);
$timeline-bar-3: rgba(25, 205, 206, 1);
$timeline-bar-4: red;
$timeline-bar-5: green;
$timeline-bar-6: blue;

body,
html {
  font-size: 12px;
}

.timeline-bars {
  > div:nth-of-type(1) {
    background-image: linear-gradient(
      $timeline-bar-1 0%,
      85%,
      opacify($timeline-bar-1, 0.4) 100%
    ) !important;
  }

  > div:nth-of-type(2) {
    background-image: linear-gradient(
      $timeline-bar-2 0%,
      85%,
      opacify($timeline-bar-2, 0.4) 100%
    ) !important;
  }

  > div:nth-of-type(3) {
    background-image: linear-gradient(
      $timeline-bar-3 0%,
      85%,
      opacify($timeline-bar-3, 0.4) 100%
    ) !important;
  }

  > div:nth-of-type(4) {
    background-image: linear-gradient(
      $timeline-bar-4 0%,
      85%,
      opacify($timeline-bar-4, 0.4) 100%
    ) !important;
  }

  > div:nth-of-type(5) {
    background-image: linear-gradient(
      $timeline-bar-5 0%,
      85%,
      opacify($timeline-bar-5, 0.4) 100%
    ) !important;
  }

  > div:nth-of-type(6) {
    background-image: linear-gradient(
      $timeline-bar-6 0%,
      85%,
      opacify($timeline-bar-6, 0.4) 100%
    ) !important;
  }
}

.touchpoint-selected {
  border: 1px red solid;

  &:after {
  }
}

.nav-dd:hover {
  > div {
    display: block !important;
  }
}

h1 strong {
  -webkit-text-fill-color: #000 !important;
  -webkit-text-stroke-width: 2px !important;
}

.blur-2 {
  filter: blur(0.3rem);
}

.blur-0 {
  filter: blur(0);
}

.column-3 {
  column-count: 3;
}

label.radio-button {
  position: relative;
  left: 20px;
  cursor: pointer;

  span.radio-button {
    position: absolute;
    top: 1px;
    left: -20px;
    height: 8px;
    width: 8px;
    border: 1px black solid;
    border-radius: 10px;
  }

  &.checked span.radio-button {
    background: black;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span.radio-button {
      background: black;
    }
  }

  &.disabled {
    color: gray;

    span.radio-button {
      border-color: gray;
    }
  }
}

button:disabled {
  opacity: 0.5;
}

.text-8xl {
  font-size: 6rem;
}

h1.story-title {
  min-height: 22rem;
}

.data em {
  font-size: 0.8rem;
  color: gray;
  font-style: normal;
}

.react-tabs {
  &__tab {
    &--selected {
      border: none !important;
      background: black;
      border-radius: 9999px;
      color: white;
    }
  }

  &__tab-panel {
    display: none !important;

    &--selected {
      display: block !important;
    }

    &--selected.flex {
      display: flex !important;
    }
  }
}

.calendar-team-icons {
  > img {
    max-width: 2.5rem;
    position: relative;

    &:first-of-type {
      left: 4px;
    }

    &:last-of-type {
      left: -4px;
    }
  }
}

.calendar-day {
  max-width: 70px;
}
