.body {
}

.months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.caption {
  margin-bottom: 1.5em;
  text-align: left;
  font-weight: 500;
  font-size: 1.25em;
  display: none;
}

.month {
  /* Month table container */
  width: 100%;
}
.month_table {
  /* Month table */
  border-collapse: collapse;
}
.month_tbody {
  /* Tbody element in the table */
}

.head {
  /* Head element (weekday names) */
}
.head_row {
  /* Head element (weekday names) row */
}
.head_weeknumber {
  font-variant-numeric: tabular-nums;
}
.head_weekname {
  padding-bottom: 0.5em;
  font-weight: normal;
  font-size: 0.75em;
}

.week {
}
.weekdays {
}
.weekdaysRow,
.week {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.weekNumber {
  /* Week number table cell */
  padding-bottom: 0.5em;
  min-width: 1.25em;
  text-align: right;
  font-weight: normal;
  font-size: 0.625em;
}

.wrapper {
  position: relative;
}
.weekday,
.day {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  min-width: 2.2em;
  height: 2.2em;
  border: 0;
  background: none;
  color: inherit;
  font-size: 1em;
  cursor: pointer;
  appearance: none;
}

.disabled,
.outside {
  opacity: 0.25;
  cursor: default;
}

.today {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
.selected {
  border-radius: 3px;
  background-color: black;
  color: white;
}
