.toggle {
  background: #ededed;
  color: #2a2a2a;
  line-height: 1;

  :checked + label {
    span:first-of-type {
      background: #2a2a2a;
      color: #ededed;
    }
  }

  :not(:checked) + label {
    span:last-of-type {
      background: #2a2a2a;
      color: #ededed;
    }
  }
}
