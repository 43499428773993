.body {
}

.months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.caption {
  margin-bottom: 1.5em;
  text-align: left;
  font-weight: 500;
  font-size: 1.25em;
}

.month {
  /* Month table container */
  margin: 1em;
  width: 100%;
  height: 230px;
}
.month_table {
  /* Month table */
  border-collapse: collapse;
}
.month_tbody {
  /* Tbody element in the table */
}

.head {
  /* Head element (weekday names) */
}
.head_row {
  /* Head element (weekday names) row */
}
.head_weeknumber {
  font-variant-numeric: tabular-nums;
}
.head_weekname {
  padding-bottom: 0.5em;
  font-weight: normal;
  font-size: 0.75em;
}

.week {
}
.weekdays {
}
.weekdaysRow,
.week {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.weekNumber {
  /* Week number table cell */
  padding-bottom: 0.5em;
  min-width: 1.25em;
  text-align: right;
  font-weight: normal;
  font-size: 0.625em;
}

.wrapper {
  position: relative;
}
.navBar {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
}
.navButtonPrev,
.navButtonNext {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.navButtonPrev {
  right: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4yOTI5IDUuMjkyOTFMMTIuNzA3MSA2LjcwNzEyTDkuNDE0MiAxMEwxMi43MDcxIDEzLjI5MjlMMTEuMjkyOSAxNC43MDcxTDYuNTg1NzcgMTBMMTEuMjkyOSA1LjI5MjkxWiIgZmlsbD0iI0QxRDVEQiIvPgo8L3N2Zz4=");
}
.navButtonNext {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjcwNzEyIDE0LjcwNzFMNy4yOTI5MSAxMy4yOTI5TDEwLjU4NTggOS45OTk5OUw3LjI5MjkxIDYuNzA3MDlMOC43MDcxMiA1LjI5Mjg4TDEzLjQxNDIgOS45OTk5OUw4LjcwNzEyIDE0LjcwNzFaIiBmaWxsPSIjRDFENURCIi8+Cjwvc3ZnPgo=");
}
.navButtonInteractionDisabled {
}

.weekday,
.day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  min-width: 2.2em;
  height: 2.2em;
  border: 0;
  background: none;
  color: inherit;
  font-size: 1em;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.disabled {
  opacity: 0.25;
}
.outside {
  opacity: 0.25;
}
.today {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
.selected {
  border-radius: 3px;
  background-color: white;
  color: black;
}
