@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nrc-gradient {
  background: linear-gradient(180deg, #557ff0 0%, #beb1ce 69.79%, #ecc7bf 100%);
}

.ntc-gradient {
  background: linear-gradient(180deg, #74d944 0%, #f0a3b5 100%);
}

.weather-gradient {
  background: linear-gradient(180deg, #d94f43 0%, #d4befd 100%);
}

.snkrs-gradient {
  background: linear-gradient(180deg, #9965f1 0%, #f2cc46 100%);
}

.social-gradient {
  background: linear-gradient(180deg, #61a0ff 0%, #bbf262 100%);
}

.legend-mark {
  width: 10px;
  height: 10px;
  margin: 5px;
}

.section a {
  display: block;
}
.sections a[aria-current]::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #6b7280;
  display: block;
  bottom: -1px;
}
